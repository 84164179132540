
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"0158e3c200aba4a97f48f1456553eddb85542c1b":"signOut","072ca6977a9ea6ba2c9a0da6a5c45ad321ce6767":"$$ACTION_4","0bbd361420b74b71d2fab170b636fb013e9435dd":"signIn","12e8d8cb647cadf00e38eafc567cfe113f0c8a08":"updatePassword","15b14ae4385406c706015e5e5b12c0ace18bb1ea":"deleteAccount","3a466c41d6947f80371f3650cef43ab09e50ee7b":"$$ACTION_3","3ad350a804e7b201633e4201836a8746d4f0a419":"removeTeamMember","3b79d1b78ca0c2518f402a8f592e98c35c910bdb":"inviteTeamMember","466701939f173b85269c5a3130240fafca239899":"updateAccount","846f66abbf95d67fff1c0603a3e4a7e83ba46bab":"$$ACTION_1","a6719109b9fa048be44956fb67a91deecd089548":"$$ACTION_0","ac61c02cad20a2eecb800d7eda93c6b12b22656a":"$$ACTION_6","c7df27c10336e2414c2127fa98f5b9ff2b401966":"signUp","ed1545f2b23e2e9046ecf898ba1be61ca48568a6":"$$ACTION_2","f77b58c0373f4a7422c8475b17ad3c490e9c8608":"$$ACTION_5"} */ export var inviteTeamMember = createServerReference("3b79d1b78ca0c2518f402a8f592e98c35c910bdb");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var signIn = createServerReference("0bbd361420b74b71d2fab170b636fb013e9435dd");
export var signUp = createServerReference("c7df27c10336e2414c2127fa98f5b9ff2b401966");
export var signOut = createServerReference("0158e3c200aba4a97f48f1456553eddb85542c1b");
export var updatePassword = createServerReference("12e8d8cb647cadf00e38eafc567cfe113f0c8a08");
export var deleteAccount = createServerReference("15b14ae4385406c706015e5e5b12c0ace18bb1ea");
export var updateAccount = createServerReference("466701939f173b85269c5a3130240fafca239899");
export var removeTeamMember = createServerReference("3ad350a804e7b201633e4201836a8746d4f0a419");

