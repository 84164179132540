"use client";

import Link from "next/link";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { CircleIcon, Home, LogOut } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useUser } from "@/lib/auth";
import { signOut } from "@/app/(login)/actions";
import { useRouter } from "next/navigation";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user, setUser } = useUser();
  const router = useRouter();

  async function handleSignOut() {
    setUser(null);
    await signOut();
    router.push("/");
  }

  return (
    <header className="border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
        <Link href="/" className="flex items-center">
          <CircleIcon className="h-6 w-6 text-blue-300" />
          <span className="ml-2 text-xl font-semibold text-gray-900">
            SafeGuard Inc.
          </span>
        </Link>
        <div className="flex items-center space-x-4">
          <Link
            href="/pricing"
            className="text-sm font-medium text-gray-700 hover:text-gray-900"
          >
            Pricing
          </Link>
          <Link
            href="/download"
            className="text-sm font-medium text-gray-700 hover:text-gray-900"
          >
            Download
          </Link>
          {user ? (
            <DropdownMenu open={isMenuOpen} onOpenChange={setIsMenuOpen}>
              <DropdownMenuTrigger asChild>
                <Avatar className="cursor-pointer size-9">
                  <AvatarImage alt={user.name || ""} />
                  <AvatarFallback>
                    {user.email
                      .split(" ")
                      .map((n) => n[0])
                      .join("")}
                  </AvatarFallback>
                </Avatar>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="p-0">
                <DropdownMenuItem className="w-full cursor-pointer m-1">
                  <Link href="/dashboard" className="flex w-full items-center">
                    <Home className="mr-2 h-4 w-4" />
                    <span>Dashboard</span>
                  </Link>
                </DropdownMenuItem>
                <form action={handleSignOut} className="p-1">
                  <button type="submit" className="flex w-full">
                    <DropdownMenuItem className="w-full cursor-pointer">
                      <LogOut className="mr-2 h-4 w-4" />
                      <span>Sign out</span>
                    </DropdownMenuItem>
                  </button>
                </form>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <Button
              asChild
              className="bg-black hover:bg-gray-800 text-white text-sm px-4 py-2 rounded-full"
            >
              <Link href="/sign-up">Sign Up</Link>
            </Button>
          )}
        </div>
      </div>
    </header>
  );
}

function Footer() {
  return (
    <footer className="bg-gray-100 border-t border-gray-200 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="col-span-2">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">
              SafeScanner Inc.
            </h3>
            <p className="text-sm text-gray-600 mb-4">
              Providing cutting-edge cybersecurity solutions to protect your
              digital assets.
            </p>
            <p className="text-sm text-gray-600">
              © 2024 SafeScanner Inc. All rights reserved. SafeScanner™ is a
              registered trademark.
            </p>
          </div>
          <div>
            <h4 className="text-md font-semibold text-gray-900 mb-4">
              Quick Links
            </h4>
            <ul className="space-y-2">
              <li>
                <Link
                  href="/pricing"
                  className="text-sm text-blue-600 hover:underline"
                >
                  Pricing
                </Link>
              </li>
              <li>
                <Link
                  href="/download"
                  className="text-sm text-blue-600 hover:underline"
                >
                  Download
                </Link>
              </li>
              <li>
                <Link
                  href="/dashboard"
                  className="text-sm text-blue-600 hover:underline"
                >
                  Dashboard
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="text-md font-semibold text-gray-900 mb-4">
              Support
            </h4>
            <ul className="space-y-2">
              <li>
                <a
                  href="/contact-us"
                  className="text-sm text-blue-600 hover:underline"
                >
                  Help Center
                </a>
              </li>
              <li>
                <a
                  href="/contact-us"
                  className="text-sm text-blue-600 hover:underline"
                >
                  Contact Us
                </a>
              </li>
              <li>
                <a
                  href="/contact-us"
                  className="text-sm text-blue-600 hover:underline"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="/contact-us"
                  className="text-sm text-blue-600 hover:underline"
                >
                  Terms of Service
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-200 text-center">
          <p className="text-sm text-gray-600">
            Supported and hosted by{" "}
            <a
              href="https://nohost.co"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              @nohost.co
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <section className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">{children}</main>
      <Footer />
    </section>
  );
}
